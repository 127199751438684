import 'magnific-popup';

$(function () {
  $('.parent-container').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: { //ギャラリー表示にする
      enabled: true
    },
    mainClass: 'mfp-fade01',
    removalDelay: 200
  });

  $('.popup-player').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade01',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    iframe: {
      markup: '<div class="mfp-iframe-scaler">' +
        '<div class="mfp-close"></div>' +
        '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
        '</div>',

      srcAction: 'iframe_src',
    }
  });
});


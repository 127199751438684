import 'slick-carousel';
$('.top-mv-slides').slick({
  arrows: false,
  dots: false,
  fade: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  autoplaySpeed: 4500,
  speed: 1300,
  pauseOnHover: false,
  pauseOnFocus: false,

});
$('.top-realreport-slides').slick({
  arrows: true,
  dots: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  centerMode: true,
  autoplaySpeed: 4200,
  speed: 800,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,

});
$('.dress-weddingdress-slides').slick({
  arrows: true,
  dots: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  autoplaySpeed: 4200,
  speed: 800,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,

});

$('.cuisine-feature-slides').slick({
  arrows: true,
  dots: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  autoplaySpeed: 4200,
  speed: 800,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,

});

$('.party-content-point-slides').slick({
  arrows: false,
  dots: true,
  fade: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  autoplaySpeed: 4200,
  speed: 800,
  pauseOnHover: false,
  pauseOnFocus: false,

});

$('.garden-style-slides').slick({
  arrows: true,
  dots: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  autoplaySpeed: 4200,
  speed: 800,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,

});

$('.about-feature-slides').slick({
  arrows: false,
  dots: true,
  fade: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  autoplaySpeed: 4200,
  speed: 800,
  pauseOnHover: false,
  pauseOnFocus: false,

});

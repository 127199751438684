import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });


let menuFlg = true;
let contactFlg = true;
$(document).on('click', '.menu-btn', function () {
  let $this = $(this);
  let $tar = $this.next();
  $this.find('.menu-trigger').stop().toggleClass('active');
  if (menuFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.3
    });
    $tar.addClass('is_active');
    //$this.find('.word').text('CLOSE');
    //$this.find('.word').addClass('active');
    $('header .wrap').addClass('active');
    $('body').addClass('stopscroll');
    $('html').addClass('stopscroll');
    menuFlg = false;
    if (contactFlg == false) {
      let $closetar = $('.inquiry-btn').next();
      gsap.to($closetar, {
        autoAlpha: 0,
        duration: 0.3
      });

      contactFlg = true;
    }

  } else {

    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.3
    });
    $tar.removeClass('is_active');
    //$(this).find('.word').text('MENU');
    //$(this).find('.word').removeClass('active');
    $('header .wrap').removeClass('active');
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    menuFlg = true;
  }
  return false;
});

$(document).on('click', '.inquiry-btn', function () {
  let $this = $(this);
  let $tar = $this.next();
  if (contactFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.3
    });
    $tar.addClass('is_active');
    $this.addClass('is_active');
    if (window.matchMedia('(max-width: 1000px)').matches) {
      $('header .wrap').addClass('active');
      $('body').addClass('stopscroll');
      $('html').addClass('stopscroll');
    }
    contactFlg = false;

    if (menuFlg == false) {
      let $closetar = $('.menu-btn').next();
      gsap.to($closetar, {
        autoAlpha: 0,
        duration: 0.3
      });
      $('.menu-btn').find('.menu-trigger').stop().removeClass('active');
      menuFlg = true;
    }
  } else {
    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.3
    });
    $tar.removeClass('is_active');
    if (window.matchMedia('(max-width: 1000px)').matches) {
      $('header .wrap').removeClass('active');
      $('body').removeClass('stopscroll');
      $('html').removeClass('stopscroll');
    }
    contactFlg = true;
  }
  return false;
});

